const homePageContent = {
    title: 'Home',
    description: `Welcome to **Saved Clay**. Explore studies, and blogs, based on and around Biblical things, Godly things, and other notes that I've put together over time.`,
    note: `Most verses will be quoted from the English Standard Version translation of the bible. I will specify when doing otherwise.\m-b
    
    **-**\m-b

    \n**Why is this website named Saved Clay?**
    \nThe title, refers to both this verse from Isaiah:\m-b
    **Isaiah 64:8**
    *"But now, O Lord , you are our Father; we are the clay,
    *and you are our potter; we are all the work of your hand."\m-b

    \nAnd more specifically this verse from Romans, in which the Apostle Paul defends the doctrine of predestination:\m-b
    
    \n**Romans 9:21**
    *"Has the potter no right over the clay, to make out of
    *the same lump one vessel for honorable use and another
    *for dishonorable use?"\m-b

    **-**\m-b
    
    I do feel that it's also important to stress that this website is not geared towards excessive public use,
    I'd be surprised if anyone, apart from myself or close friends who I've mentioned it to, was to even
    come across it, but just as a disclaimer anyway, I have made this website mostly for the continuation of my
    personal study of scripture and also memorization of certain parts of scripture. But I am not opposed to anyone
    who might find themselves using it as a resource in their own studies also. And for this reason, I've made some
    of the resources on this site public. Including of course the blog posts which I'm happy for anyone to read.
    Just keep in mind that I'm only a novice in Godly things, and Christian living. Being a fairly recent convert.\m-b
    
    **-**\m-b

    **Links I find useful:**
    *Goes without saying, however, that you should take
    *their info with a grain of salt and do your own research!\m-b

    Helpful PDF:
    {link:https://www.thecollegechurch.org/wp-content/uploads/2016/08/HANDOUTS-Is-Scripture-Reliable.pdf}
    \m-b

    Bible Timeline:
    {link:https://biblehub.com/timeline/}`
  };

export default homePageContent;