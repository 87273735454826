import React, { useState, useEffect } from 'react';
import { firestore, collection, getDocs, query, orderBy } from './firebase-config';
import { Link } from 'react-router-dom';
import './BlogList.css';

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('All');

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = collection(firestore, 'posts');
      const postsQuery = query(postsCollection, orderBy('createdAt', 'desc')); // Order by createdAt descending
      const postsSnapshot = await getDocs(postsQuery);
      const postsList = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(postsList);
    };

    fetchPosts();
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  };

  const filteredPosts = categoryFilter === 'All' ? posts : posts.filter(post => post.category === categoryFilter);
  const categories = ['All', ...new Set(posts.map(post => post.category).filter(Boolean))];

  return (
    <div className="blog-list">
      <h1>Blog Posts</h1>
      <div className="category-filter">
        <label htmlFor="category">Filter by Category: </label>
        <select id="category" value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)}>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>
      <ul>
        {filteredPosts.map(post => (
          <li key={post.id}>
            <Link to={`/post/${post.id}`}>
              <h2>{post.title}</h2>
              <p>Category: {post.category || 'Uncategorized'} | Published: {formatDate(post.createdAt)}</p>
              {/* <p></p> */}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogList;