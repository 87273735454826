import { BrowserRouter as Router, Route, Routes, Navigate, Link  } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import AdminDashboard from './AdminDashboard';
import BlogList from './BlogList';
import BlogPost from './BlogPost';
import Notes from './BibleNotes.js'; // Import notes data
import Doctrines from './Doctrines.js'; // Import doctrines data
import barnabas from './bible_character_studies/barnabas.js'; // Import barnabas character data
import testCharacter from './bible_character_studies/test_character.js'; // Import testCharacter character data
import renderContent from './renderContent.js';
import homePageContent from './homePageContent.js';
import InstitutesTracker from './InstitutesTracker.js';
import BookTracker from './BookTracker.js';
import MemoryGame from './MemoryGame';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { collection, query, where, getDocs } from "firebase/firestore";

import { auth, firestore } from './firebase-config';
import { AuthContext, AuthProvider } from './AuthContext'; // Import AuthContext
import VerseRevision from './VerseRevision.js';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
let globalUsername = 'Guest';

function HomePage() {
  const { loggedInUser, loginUser, logoutUser } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  // Handle sign-up logic using Firebase Authentication
  const handleSignUp = async () => {
    const usernameRegex = /^[a-zA-Z0-9_]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const offensiveWords = ['shit', 'fuck', 'dick', 'nigger', 'cunt', 'bitch'];

    if (offensiveWords.some(word => username.toLowerCase().includes(word.toLowerCase()))) {
      setMessage('Username contains offensive language. Please choose a different one.');
      return;
    }

    if (!usernameRegex.test(username)) {
      setMessage('Username can only contain letters, numbers, and underscores.');
      return;
    }

    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email address.');
      return;
    }

    try {
      // Check if username already exists
      const usersRef = collection(firestore, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setMessage('Username is already taken. Please choose another.');
        return;
      }

      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store additional user details in Firestore
      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(userRef, {
        uid: user.uid,
        username,
        email,
        role: 'User',
        buttonScore: 0,
      });

      setMessage('Sign-up successful! You can now log in.');
      setIsLogin(true);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Handle login logic using Firebase Authentication
  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch user details from Firestore
      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        loginUser({ username: userData.username, email: userData.email, role: userData.role });
        setMessage(`Welcome back, ${userData.username}!`);
      } else {
        setMessage('User data not found. Please contact support.');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      logoutUser();
      setMessage('Logged out successfully.');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="home-page">
      <h2>{renderContent(homePageContent.title)}</h2>
      <p>{renderContent(homePageContent.description)}</p>
      <p>{renderContent(homePageContent.note)}</p>

      <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto' }}>
        <h1>{isLogin ? 'Login' : 'Sign Up'}</h1>

        {loggedInUser ? (
          <div>
            <p>
              <strong>Welcome, {loggedInUser.username}!</strong>
            </p>
            <button onClick={handleLogout} style={{ width: '100%', marginBottom: '10px' }}>
              Log Out
            </button>
          </div>
        ) : (
          <div>
            {!isLogin && (
              <div>
                <label>Username:</label>
                <input
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  style={{ display: 'block', marginBottom: '10px', width: '98%' }}
                />
              </div>
            )}

            <div>
              <label>Email:</label>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ display: 'block', marginBottom: '10px', width: '98%' }}
              />
            </div>

            <div>
              <label>Password:</label>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ display: 'block', marginBottom: '20px', width: '98%' }}
              />
            </div>

            {isLogin ? (
              <button onClick={handleLogin} style={{ marginBottom: '10px', width: '100%' }}>
                Log In
              </button>
            ) : (
              <button onClick={handleSignUp} style={{ marginBottom: '10px', width: '100%' }}>
                Sign Up
              </button>
            )}

            <button
              onClick={() => setIsLogin(!isLogin)}
              style={{ display: 'block', marginTop: '10px', width: '100%' }}
            >
              {isLogin ? "Don't have an account? Sign Up" : 'Already have an account? Log In'}
            </button>
          </div>
        )}

        {message && <p style={{ color: isLogin ? 'green' : 'blue', marginTop: '20px' }}>{message}</p>}
      </div>
    </div>
  );
}


const scrollToLowerPage = () => {
  const element = document.getElementById('lower-page');
  element.scrollIntoView({ behavior: 'smooth' });
};

function App() {
  const { loggedInUser } = useContext(AuthContext); // Access loggedInUser
  const [currentPage, setCurrentPage] = useState('home');
  const [isCharacterDropdownOpen, setIsCharacterDropdownOpen] = useState(false);
  const [isNotesDropdownOpen, setIsNotesDropdownOpen] = useState(false);
  const [isDoctrinesDropdownOpen, setIsDoctrinesDropdownOpen] = useState(false); // Doctrines dropdown state
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedDoctrine, setSelectedDoctrine] = useState(null); // Doctrine selection
  const [characters] = useState([barnabas, testCharacter]); // Updated characters array
  const [darkMode, setDarkMode] = useState(true); // State for dark mode
  const [notePassword, setNotePassword] = useState('');
  const [isPasswordPromptVisible, setIsPasswordPromptVisible] = useState(false);
  const [passwordProtectedNote, setPasswordProtectedNote] = useState(null);

  const toggleCharacterDropdown = () => {
    setIsCharacterDropdownOpen(!isCharacterDropdownOpen);
    if (!isCharacterDropdownOpen) {
      setIsNotesDropdownOpen(false);
      setIsDoctrinesDropdownOpen(false); // Close doctrines dropdown if opened
    }
  };

  const toggleNotesDropdown = () => {
    setIsNotesDropdownOpen(!isNotesDropdownOpen);
    if (!isNotesDropdownOpen) {
      setIsCharacterDropdownOpen(false);
      setIsDoctrinesDropdownOpen(false); // Close doctrines dropdown if opened
    }
  };

  const toggleDoctrinesDropdown = () => {
    setIsDoctrinesDropdownOpen(!isDoctrinesDropdownOpen);
    if (!isDoctrinesDropdownOpen) {
      setIsCharacterDropdownOpen(false);
      setIsNotesDropdownOpen(false); // Close notes dropdown if opened
    }
  };

  const selectCharacter = (character) => {
    setSelectedCharacter(character);
    setCurrentPage('characterStudy');
    setIsCharacterDropdownOpen(false); // Close the character dropdown after selecting a character
    setTimeout(scrollToLowerPage, 20);
  };

  const handlePasswordSubmit = () => {
    if (passwordProtectedNote.password === notePassword) {
      setSelectedNote(passwordProtectedNote);
      setIsNotesDropdownOpen(false);
      setCurrentPage('noteContent');
      setTimeout(scrollToLowerPage, 20);
      setIsPasswordPromptVisible(false);
      setNotePassword('');
      setPasswordProtectedNote(null);
    } else {
      alert('Incorrect password!');
    }
  };

  const handlePasswordCancel = () => {
    setIsPasswordPromptVisible(false);
    setNotePassword('');
    setPasswordProtectedNote(null);
  };

  const selectNote = (note) => {
    if (note.password) {
      setPasswordProtectedNote(note);
      setIsPasswordPromptVisible(true);
      setIsNotesDropdownOpen(false);
    } else {
      setSelectedNote(note);
      setIsNotesDropdownOpen(false); // Close the notes dropdown after selecting a note
      setCurrentPage('noteContent');
      setTimeout(scrollToLowerPage, 20);
    }
  };

  const selectDoctrine = (doctrine) => {
    setSelectedDoctrine(doctrine);
    setIsDoctrinesDropdownOpen(false); // Close the doctrines dropdown after selecting a doctrine
    setCurrentPage('doctrineContent');
    setTimeout(scrollToLowerPage, 20);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (<AuthProvider>
   
      <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
        <header className="App-header">
          <h1>Saved Clay</h1>
          <nav className="navigation">
            <Link to="/blog">
            <button>Blog</button>
            </Link>
            <Link to="/"><button>Home</button></Link>
            <Link to="/institutes-tracker"><button>Institutes Tracker</button></Link>
            <Link to="/book-tracker"><button>Book Tracker</button></Link>
            {loggedInUser?.username === "Aaron" && (
              <>
                <Link to="/admin"><button>Admin</button></Link>
                <Link to="/verse-revision"><button>Verse Revision</button></Link>
                <div className="dropdown">
                  <button className="dropdown-button"  onClick={toggleNotesDropdown}>
                    Notes {isNotesDropdownOpen ? '▲' : '▼'}
                  </button>
                  <div className={`dropdown-content ${isNotesDropdownOpen ? 'show' : ''}`}>
                    {Notes.map((note) => (
                      <div key={note.id} onClick={() => selectNote(note)} to={`/notes/${note.id}`} className="dropdown-item">
                        {note.title}
                      </div>
                    ))}
                  </div>
                  <button className="dropdown-button" onClick={toggleDoctrinesDropdown}>
                    Doctrines {isDoctrinesDropdownOpen ? '▲' : '▼'}
                  </button>
                  <div className={`dropdown-content ${isDoctrinesDropdownOpen ? 'show' : ''}`}>
                    {Doctrines.map((doctrine) => (
                      <div key={doctrine.id} to={`/doctrines/${doctrine.id}`} onClick={() => selectNote(doctrine)} className="dropdown-item">
                        {doctrine.title}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <Link to="/memory-game"><button>Memory Game</button></Link>
          </nav>
        </header>
        
        <div className="container" id="lower-page">
          <Routes>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/post/:id" element={<BlogPost />} />
            <Route path="/institutes-tracker" element={<InstitutesTracker username={loggedInUser?.username || 'Guest'} />} />
            <Route path="/book-tracker" element={<BookTracker username={loggedInUser?.username || 'Guest'} />} />
            <Route path="/verse-revision" element={<VerseRevision  />} />
            <Route path="/notes/:id" element={<Notes />} />
            <Route path="/doctrines/:id" element={<Doctrines />} />
            <Route path="/memory-game" element={<MemoryGame />} />
            <Route path="/" element={<HomePage />} />
          </Routes>


          {selectedNote && (
  <div className="note-content-page">
    <h2>{selectedNote.title}</h2>
    {selectedNote.title.includes("Greek") ? (
      shuffleArray(selectedNote.sections).map((section) => (
        <NoteSection
          key={`${selectedNote.id}-${section.id}`}
          displaytitle={section.displaytitle}
          displaydescription={section.displaydescription}
          title={section.title}
          content={section.content}
        />
      ))
    ) : (
      selectedNote.sections.map((section) => (
        <NoteSection
          key={`${selectedNote.id}-${section.id}`}
          displaytitle={section.displaytitle}
          displaydescription={section.displaydescription}
          title={section.title}
          content={section.content}
        />
      ))
    )}
  </div>
)}
          
        
        </div>
      </div>
    
  </AuthProvider>
  );
}
const NoteSection = ({ displaytitle, displaydescription, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="note-section">
      {displaytitle && <h3 className="note-display-title">{displaytitle}</h3>}
      {displaydescription && <p className="note-display-description">{displaydescription}</p>}
      <div className="note-section-title" onClick={toggleSection}>
        {title}
      </div>
      {isOpen && (
        <div className="note-section-content active">
          {renderContent(content)}
        </div>
      )}
    </div>
  );
};

// Function to shuffle array
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default App;
