import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { firestore, addDoc, updateDoc, doc, collection } from './firebase-config';
import { serverTimestamp, getDoc } from 'firebase/firestore'; // Importing serverTimestamp and getDoc
import './AddEditPost.css';

const AddEditPost = ({ post, requireCategory }) => {
  const [title, setTitle] = useState(post ? post.title : '');
  const [content, setContent] = useState(post ? post.content : '');
  const [category, setCategory] = useState(post ? post.category : '');

  useEffect(() => {
    if (post) {
      setTitle(post.title);
      setContent(post.content);
      setCategory(post.category || '');
    }
  }, [post]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (requireCategory && !category) {
      alert('Category is required.');
      return;
    }

    const postData = {
      title,
      content,
      category,
      updatedAt: serverTimestamp()
    };

    if (post) {
      // Get the original createdAt timestamp if it exists
      const postDoc = await getDoc(doc(firestore, 'posts', post.id));
      const createdAt = postDoc.data()?.createdAt || serverTimestamp();

      await updateDoc(doc(firestore, 'posts', post.id), { ...postData, createdAt });
    } else {
      await addDoc(collection(firestore, 'posts'), { ...postData, createdAt: serverTimestamp() });
    }
    setTitle('');
    setContent('');
    setCategory('');
  };

  return (
    <form onSubmit={handleSubmit} className="add-edit-post">
      <input
        type="text"
        placeholder="Title"
        className='title-input'
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />
      <ReactQuill
        value={content}
        onChange={setContent}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered'}, { list: 'bullet' }],
            ['link', 'image'],
            ['clean']
          ],
        }}
        formats={[
          'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'link', 'image'
        ]}
      />
      <input
        type="text"
        placeholder="Category"
        className='category-input'
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        required={requireCategory}
      />
      <button type="submit">{post ? 'Update Post' : 'Add Post'}</button>
    </form>
  );
};

export default AddEditPost;
