// src/BlogPost.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore, doc, getDoc } from './firebase-config';
import DOMPurify from 'dompurify';
import './BlogPost.css';

const BlogPost = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  };
  useEffect(() => {
    const fetchPost = async () => {
      const postDoc = await getDoc(doc(firestore, 'posts', id));
      setPost({ id: postDoc.id, ...postDoc.data() });
    };

    fetchPost();
  }, [id]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="blog-post">
      <h1>{post.title}</h1>
      <p>Published: {formatDate(post.createdAt)}</p>
      <div
        className="post-content"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}
      />
    </div>
  );
};

export default BlogPost;
