import React, { useState, useEffect, useContext } from 'react';
import { firestore, collection, getDocs, deleteDoc, doc } from './firebase-config';
import AddEditPost from './AddEditPost';
import './AdminDashboard.css';
import { AuthContext } from './AuthContext'; // Import AuthContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AdminDashboard = () => {
  const { loggedInUser } = useContext(AuthContext); // Access loggedInUser
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchPosts = async () => {
      const postsCollection = collection(firestore, 'posts');
      const postsSnapshot = await getDocs(postsCollection);
      const postsList = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPosts(postsList);
    };

    fetchPosts();
  }, []);

  const handleDelete = async (id) => {
    await deleteDoc(doc(firestore, 'posts', id));
    setPosts(posts.filter(post => post.id !== id));
  };

  // Redirect to home page if the user is not an admin
  useEffect(() => {
    if (!loggedInUser || loggedInUser.username !== 'Aaron') {
      navigate('/'); // Redirect to home page
    }
  }, [loggedInUser, navigate]);

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <AddEditPost requireCategory={true} />
      <ul>
        {posts.map(post => (
          <li key={post.id}>
            <h2>{post.title}</h2>
            <p>Category: {post.category || 'Uncategorized'}</p>
            <button onClick={() => handleDelete(post.id)}>Delete</button>
            <AddEditPost post={post} requireCategory={true} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminDashboard;
