// src/firebase-config.js
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'; // For Firestore
import { getDatabase } from 'firebase/database';  // For Realtime Database
import { getAuth } from 'firebase/auth';
import { 
  collection, 
  doc, 
  setDoc, 
  getDoc,
  deleteDoc, 
  onSnapshot, 
  getDocs, 
  addDoc,
  updateDoc,       // <-- Add updateDoc here
  query,        // Import query
  where,        // Import where
  orderBy      // <-- Import orderBy
} from 'firebase/firestore'; 

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAmBkIvanX3xcEjS3wfv8lB8LWKsxdeQRM",
    authDomain: "solagrace-defcf.firebaseapp.com",
    projectId: "solagrace-defcf",
    storageBucket: "solagrace-defcf.firebasestorage.app",
    messagingSenderId: "688560637655",
    appId: "1:688560637655:web:a468a769b53ad153fed292",
    measurementId: "G-8YHV1X9FPX"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const firestore = getFirestore(app); // Firestore
export const database = getDatabase(app);  // Realtime Database
export const auth = getAuth(app);
export { 
  collection, 
  doc, 
  setDoc,
  getDoc,
  deleteDoc, 
  updateDoc,      // <-- Export updateDoc here
  onSnapshot, 
  getDocs, 
  addDoc, 
  query,      // Export query
  where,      // Export where
  orderBy     // <-- Export orderBy
};